import React, { useState } from 'react'
import { validateEmpty } from '../../utils/validation'

const QandAForm = props => {

	const { action, formName } = props

	const [name, setName] = useState('')
	const [nameIsValid, setNameIsValid] = useState(true)
	const [question, setQuestion] = useState('')
	const [questionIsValid, setMessageIsValid] = useState(true)
	const [captcha, setCaptcha] = useState('')
	const [captchaIsValid, setCaptchaIsValid] = useState(true)

	const [formIsValid, setFormIsValid] = useState(false)
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [lastQuestion, setLastQuestion] = useState(``)

	const submitUrl = action || '/'

	const handleNameInputChange = event => {
		setName(event.target.value)
		setNameIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleQuestionInputChange = event => {
		setQuestion(event.target.value)
		setMessageIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleCaptchaInputChange = event => {
		setCaptcha(event.target.value)
		setCaptchaIsValid(!validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleFormValidation = () => {
		setFormIsValid(
			validateEmpty(name) &&
				// validateEmail(email) &&
				validateEmpty(question) &&
				!validateEmpty(captcha)
		)
	}

	const handleSubmit = event => {
		event.target.setAttribute('disabled', true)
		submitForm().then(
			event.target.removeAttribute('disabled')
		)
	}

	const encodeForm = (data) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	const submitForm = async (event) => {
		// submits netlify form via fetch
		setShowError(false)
		setShowSuccess(false)

		const data = {
			name: name,
			question: question
		}

		await fetch(submitUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: encodeForm({'form-name': formName, ...data}),
		})
			.then((response) => {
				console.log(response)
				if (response.status === 200) {
					setShowSuccess(true)
					setLastQuestion(question)
					setQuestion(``)
					// hide the success message after 5 seconds
					setTimeout(() => {
						setShowSuccess(false)
					}, 5000)
				} else {
					setShowError(true)
					// hide the error message after 5 seconds
					setTimeout(() => {
						setShowError(false)
					}, 5000)
				}
			})
			.catch(error => setShowError(true))
	}

	const labelStyles = `text-bbf-dark-green`
	const invalidLabelStyles = `text-bbf-red`
	const inputStyles = `transition-all w-full py-2 px-4 text-xl text-bbf-dark-green shadow-inner border-2 border-bbf-dark-green focus:outline-none focus:ring-4 focus:ring-bbf-mid-green focus:ring-opacity-50`
	const invalidInputStyles = `text-bbf-red border-bbf-red`
	const validationMessageStyles = `transition text-sm text-bbf-red mb-2 opacity-0`


	return (
		<form
			action={action}
			method="POST"
			name={formName}
			className="w-full"
			data-netlify={true}
			data-netlify-honeypot="captcha"
		>
			<input type="hidden" name="form-name" value={formName} />

			<label className={`flex flex-col items-start`}>
				<p className={`${labelStyles} ${!nameIsValid && invalidLabelStyles}`}>Your name</p>
				<input
					type="text"
					name="name"
					value={name}
					placeholder={``}
					className={`${inputStyles} ${!nameIsValid && invalidInputStyles}`}
					onChange={handleNameInputChange}
					onKeyUp={handleNameInputChange}
					required
				/>
			</label>
			<p className={`${validationMessageStyles} ${!nameIsValid && 'opacity-100'}`}>
				Please enter your name
			</p>

			<label className={`flex flex-col items-start`}>
				<p className={`${labelStyles} ${!questionIsValid && invalidLabelStyles}`}>Your Question</p>
				<textarea
					name="question"
					value={question}
					placeholder={``}
					className={`h-32 ${inputStyles} ${!questionIsValid && invalidInputStyles}`}
					onChange={handleQuestionInputChange}
					onKeyUp={handleQuestionInputChange}
					required
				/>
			</label>
			<p className={`${validationMessageStyles} ${!questionIsValid && 'opacity-100'}`}>
				Please enter a question
			</p>

			{ lastQuestion !== `` && (
				<div className={`-mt-4 mb-4 p-2 shadow-inner text-sm bg-gray-100`}>
					<p className={`text-xs text-gray-600`}>You asked:</p>
					<p>{lastQuestion}</p>
				</div>
			) }

			<label className={`flex flex-col items-start hidden`}>
				<p className={`${labelStyles} ${!captchaIsValid && invalidLabelStyles}`}>Captcha</p>
				<input
					type="text"
					name="captcha"
					value={captcha}
					placeholder={
						props.captchaPlaceholderText ||
						"if you can see this, don't fill it in!"
					}
					className={`${inputStyles} ${!captchaIsValid && invalidInputStyles}`}
					onChange={handleCaptchaInputChange}
					onKeyUp={handleCaptchaInputChange}
				/>
			</label>
			<p className={`${validationMessageStyles} ${!captchaIsValid && 'opacity-100'} hidden`}>
				Please do NOT fill this in
			</p>

			{showError && (
				<p className="mb-4 py-2 px-4 text-white bg-bbf-red font-semibold">
					There was a problem submitting your question. Please try again.
				</p>
			)}

			{showSuccess && (
				<p className="mb-4 py-2 px-4 text-white bg-bbf-mid-green-alt font-semibold">
					Thank you! Your question has been submitted.
				</p>
			)}

			<button
				className={`transition-all w-full text-white ${
					formIsValid ? 'bg-bbf-teal' : 'bg-gray-400 cursor-not-allowed'
				} py-3 px-6 uppercase shadow transition-all focus:outline-none focus:shadow-outline`}
				type="button"
				disabled={!formIsValid}
				onClick={handleSubmit}
			>
				{props.submitButtonText ? props.submitButtonText : 'Submit Question'}
			</button>
		</form>
	)
}

export default QandAForm
