import React, { useState, useEffect } from 'react'
import CloseIcon from '../Icon/CloseIcon'
import QandAForm from '../Form/QandAForm'
import QuestionIcon from '../Icon/QuestionIcon'

const LiveEventQandAForm = (props) => {

	const { eventStart, eventEnd, eventFormName } = props

	// It's critical the state for showing/hiding the form is maintained in this component,
	// otherwise re-rendering affects the livestream video

	const [showQuestionForm, setShowQuestionForm] = useState(false)
	const [openForm, setOpenForm] = useState(false)
	const [eventHasStarted, setEventHasStarted] = useState(false)
	const [eventHasFinished, setEventHasFinished] = useState(false)
	const [currentDate, setCurrentDate] = useState(new Date().getTime())

	useEffect(() => {

		// check the start and end time of the event, only make the form available
		// from the start to 15 mins after the scheduled end time
		const checkShowForm = setInterval(() => {
			setCurrentDate(new Date().getTime())
			// console.log(`currentDate: ${currentDate}`)
		}, 60000)

		// check if the event has started
		if (currentDate >= eventStart) {
			setEventHasStarted(true)
		}

		const timeAfterEnd = eventEnd + 900000 // 15 mins

		if (currentDate >= timeAfterEnd) {
			setEventHasFinished(true)
		}

		// Show the form if the event is currently live
		if (eventHasStarted && !eventHasFinished) {
			setShowQuestionForm(true)
		}

		return () => { clearInterval(checkShowForm) }
	}, [currentDate, eventStart, eventEnd, eventHasStarted, eventHasFinished, showQuestionForm])

	const handleShowQuestionForm = (event) => {
		setOpenForm(true)
	}

	const handleHideQuestionForm = (event) => {
		setOpenForm(false)
	}

	return showQuestionForm ? (
		<>
			<div className={`mt-4 mx-auto max-w-lg lg:m-0 lg:ml-12 bg-gray-200 shadow-lg p-4 pt-8 lg:w-1/4 xl:w-1/5 transition-all relative ${openForm ? `` : `hidden`}`}>
				<button
					className={`text-sm absolute top-0 right-0 mr-4 mt-4 flex items-center justify-between`}
					onClick={handleHideQuestionForm}
				>
					<span className={`mr-1`}>Close</span>
					<CloseIcon />
				</button>
				<QandAForm action={`/`} formName={eventFormName} />
				<p className={`mt-4 mb-2`}>Or text <strong>66777</strong> with the word 'BOZZY', your name and question.</p>
				<p className={`text-xs mb-2`}>There may be charges for texting questions depending on your mobile network.</p>
				<p className={`text-xs`}>While we will make every effort to field as many questions as we can, there is no guarantee your question will be asked during the live event.</p>
			</div>
			<button
				type={`button`}
				className={`absolute bottom-0 right-0 mr-4 md:mr-12 z-10 text-white bg-bbf-dark-green px-4 py-2 flex items-center justify-between ${openForm ? `hidden` : ``}`}
				onClick={handleShowQuestionForm}>
				<span className={`mr-2`}>Ask a question</span>
				<QuestionIcon />
			</button>
		</>
	) : null

}

export default LiveEventQandAForm
