import React, { useState, useEffect } from 'react'
import LiveEventQandAForm from './LiveEventQandAForm'

const PrismicLiveEvent = (props) => {

	const { event, livestream, pageLocation, eventFormName } = props
	const initialUrlParams = pageLocation.search.length ? pageLocation.search : ''

	const [showLivestream, setShowLivestream] = useState(false)
	const [hasRegistered, setHasRegistered] = useState(false)
	const [currentDate, setCurrentDate] = useState(new Date().getTime())

	// To make sure the Date will be created in Safari,
	// we need to replace - for / in the date string
	const eventStart = new Date(livestream.data.livestream_start_time.replace(/-/g, "/")).getTime()
	const eventEnd = new Date(livestream.data.livestream_end_time.replace(/-/g, "/")).getTime()

	// Some events are pre-recorded
	// For the festival rerun, we are only showing the question form for the one live event
	const showQuestionsForEvents = [
		'10-29-1800-main'
	]

	const showQuestionForm = showQuestionsForEvents.includes(event.event_id)

	useEffect(() => {

		if (initialUrlParams.length) {
			const urlParams = new URLSearchParams(pageLocation.search)
			if (urlParams.get('id') === event.event_livestream_code) {
				// we can assume they have a link from registration at this point
				if (hasRegistered === false) {
					setHasRegistered(true)
				}

				const updateCurrentDate = setInterval(() => {
					setCurrentDate(new Date().getTime())
					// console.log(`currentDate: ${currentDate}`)
				}, 10000)

				if (showLivestream) {
					clearInterval(updateCurrentDate)
				}

				// check if we are within 1 hour of the event start
				const timeBeforeStart = 3600000 // 1 hour
				const dateDiff = eventStart - currentDate

				if (dateDiff <= timeBeforeStart) {
					if (showLivestream === false) {
						setShowLivestream(true)
					}
				}

				return () => { clearInterval(updateCurrentDate) }
			}
		}
	}, [currentDate, eventStart, event.event_livestream_code, hasRegistered, initialUrlParams, pageLocation, showLivestream])

	const EventRegistration = () => {
		if (hasRegistered) {
			return null
		}

		return (
			<EventMessageBanner
				title={`Registration required.`}
				description={<p>Tickets for online events at this years Festival are <strong>free of charge</strong>.<br/>
					If you would like to watch this event, please register for tickets using the big yellow button above.</p>} />
		)
	}

	const EventNotStarted = () => {
		if (showLivestream || !hasRegistered) {
			return null
		}

		return (
			<EventMessageBanner
				title={`You're Early!`}
				description={`Please come back again nearer the time, there will be things to watch up to an hour before the event starts.`} />
		)
	}

	const EventMessageBanner = (props) => {
		const { title, description } = props

		return (
			<div className={`p-4 md:p-12 border-b-4 border-bbf-dark-green`}>
				<h2	className={`text-3xl mb-6`}>{title}</h2>
				<h3 className={`text-2xl`}>{description}</h3>
			</div>
		)
	}


	const LivestreamVideo = (props) => {
		const { eventId, livestream } = props

		// console.log(livestream.frontmatter.event_livestream_link_bsl)
		// /2021/events/10-18-1000-childrens?id=a707df17fecef06164a1fdbe92488f72ffe3ca5e1738683d8990270eaf55aecb

		return (
			<div className="livestream-video-container w-full">
				<div className="livestream-video">
					{
						livestream ? (
							<div
								className="livestream-content"
								dangerouslySetInnerHTML={{ __html: livestream.html }}
							/>
						) : (
							<p className={`text-2xl`}>Livestream not available.</p>
						)
					}
				</div>
				{/* Using the BSL link to direct to part two of an event here for the rerun */}
				{
					livestream && livestream.frontmatter && livestream.frontmatter.event_livestream_link_bsl.length > 0 ? (
						<p className={`mt-6 text-2xl`}>
							<a className={`link`} href={livestream.frontmatter.event_livestream_link_bsl} >{eventId === '10-18-0900-childrens' ? 'Watch Part 2' : 'Watch with BSL on youtube.com'}</a>
						</p>
					) : ``
				}
			</div>
		)
	}

	const LivestreamSection = (props) => {

		// it's critical the props here are passed along so we don't
		// re-render the video unnecessarily and cause it to stop playing

		const { eventId, livestream } = props

		if (!showLivestream) {
			return null
		}

		return (
			<div className={`lg:flex lg:items-start p-4 md:p-12 md:pb-16 md:mb-16 border-b-4 border-bbf-dark-green bg-white relative`}>
				<LivestreamVideo livestream={livestream} eventId={eventId}/>
				{ showQuestionForm && <LiveEventQandAForm eventStart={eventStart} eventEnd={eventEnd} eventFormName={eventFormName}/> }
			</div>
		)
	}

	return (
		<>
			<LivestreamSection eventId={event.event_id} livestream={livestream} />
			<EventRegistration event={event} />
			<EventNotStarted event={event} />
		</>
	)

}

export default PrismicLiveEvent
