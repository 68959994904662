import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import TicketIcon from '../Icon/TicketIcon'
import Tippy from '@tippyjs/react'

const EventBanner = (props) => {
	const { event, showRegistration } = props

	// fix date issue in safari
	const startDate = event.event_start.replace(/-/g, "/")

	const getFormattedEventDate = () => {
		const formattedEventDate = new Intl.DateTimeFormat([], {
			localeMatcher: 'best fit',
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			timeZoneName: 'short',
			hour: 'numeric',
			minute: 'numeric'
		})
			.format(new Date(startDate))

		// return the actual event date for the one live event happening during the rerun
		if (event.event_id === '10-29-1800-main') {
			return `${formattedEventDate} and then available until 31-Oct-21`
		}

		if (['10-18-0900-childrens', '10-18-1000-childrens'].includes(event.event_id)) {
			return `Event available on demand from 18 October to 12 November`
		}

		if (event.event_type === 'childrens') {
			return `Events available on demand from 18-31 October`
		}

		return `Events available on demand from 29-31 October`
	}

	const EventInfo = (props) => {
		const { event } = props
		return (
			<div className={`bg-bbf-mid-green text-white p-4 md:px-12 md:opacity-95 md:absolute md:bottom-0 md:left-0 md:right-0 md:flex md:items-center`}>
				<div className={`pb-4 md:pr-4 md:pb-0`}>
					<h1 className={`text-2xl mb-2 md:text-4xl`}>{event.event_title}</h1>
					<p className={`text-lg md:text-2xl`}>{getFormattedEventDate()}</p>
				</div>
				{ showRegistration && (
					<div className={`md:ml-auto`}>
						<Tippy
							content={(
								<div className={`p-2 text-lg text-center`}>
									<p>All 2021 festival events are free.</p>
									<p><Link to={`/donate`} className={`link text-white`}>Make a donation to show your support.</Link></p>
								</div>
							)}
							animation="perspective"
							placement="top"
							interactive="true"
							arrow={true}
							inertia={true}
						>
							<Link
								to={`/2021/${event.event_type !== 'main' ? `${event.event_type}-festival-` : `` }box-office?date=${event.event_date}&eventid=${event.event_ft_id}`}
								state={{ eventId: event.event_ft_id }}
								title={`Register for: ${event.event_title}`}
								className={`md:text-2xl p-2 md:p-4 bg-bbf-yellow text-black shadow-md flex items-center justify-center`}
							>
								<span className={`mr-2`}>Register for tickets</span>
								<TicketIcon />
							</Link>
						</Tippy>
					</div>
				)}
			</div>
		)
	}

	return (
        <div className={`border-b-4 border-bbf-dark-green relative`}>
			<GatsbyImage
                image={event.event_image.childImageSharp.gatsbyImageData}
                objectFit="cover"
                className={`max-h-screen-70 md:max-h-screen-50`} />
			<EventInfo event={event} />
		</div>
    );
}

export default EventBanner
