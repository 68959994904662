import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/Layout/Layout'
import EventBanner from '../components/Page/EventBanner'
import QandAForm from '../components/Form/QandAForm'
import LiveEvent from '../components/Event/LiveEvent'

/**
 * TODO:
 *  - Add in a donate button to the event banner, next to the register for tickets, perhaps swap them if they've
 *  registered?
 */

export default function eventTemplate({
	data, // this prop will be injected by the GraphQL query below.
	location
}) {

	const eventData = data.event.frontmatter
	const livestream = data.livestream ? data.livestream : null
	const eventFormName = eventData.event_title

	const showLiveEvent = () => {
		// after the rerun we want the following events to be watchable until the 12th Nov
		const currentDate = new Date()
		const cutoffDate = new Date('2021-11-13') // this will be midnight by default
		// this check will take the current time into consideration, hence the date of the 13th.
		if (currentDate > cutoffDate) {
			return false
		}

		// Show the Christopher Lloyd event until the cut off date
		if (['10-18-0900-childrens', '10-18-1000-childrens'].includes(eventData.event_id)) {
			return true
		}

		return false
	}

	const showRegistration = () => {
		return showLiveEvent()
	}

	const AuthorImage = (props) => {
		const { name, image } = props
		return (
            <div className={`shadow-md relative`}>
				<GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={name}
                    className={`w-48 h-64`} />
				<p className={`absolute bottom-0 text-sm text-right px-2 text-white bg-bbf-mid-green opacity-90`}>{name}</p>
			</div>
        );
	}

	const EventDetails = () => {

		return (
			<div className={`md:w-1/2 p-4 md:p-12 md:pt-0`}>

				{/* author images */}
				<div className={`flex items-start justify-around mb-8`}>
					<AuthorImage image={eventData.author_1_image} name={eventData.author_1_name} />
					{eventData.author_2_name && (
						<AuthorImage image={eventData.author_2_image} name={eventData.author_2_name} />
					)}
				</div>

				{ (eventData.event_interviewer_name || eventData.event_compere_name) && (
					<div className={`mb-4 text-gray-600`}>
						{ eventData.event_interviewer_name && (
							<p>Chaired by: {eventData.event_interviewer_name}</p>
						)}
						{ eventData.event_compere_name && (
							<p>Host: {eventData.event_compere_name}</p>
						)}
					</div>
				)}
				{/* event description content */}
				<div className={`markdown-content`} dangerouslySetInnerHTML={{__html: data.event.html}} />
			</div>
		)
	}

	const Book = (props) => {
		const { title, link, image } = props
		return (
            <a
				href={link}
				title={title}
				target={`_blank`}
				rel={`noopener noreferrer`}
				className={`block mb-4`}
			>
				<GatsbyImage image={image} alt={title} className={`w-72 shadow-md`} />
			</a>
        );
	}

	const BookDetails = () => {
		return (
            <div className={`p-4 mx-auto md:w-1/2 md:p-12 md:pt-0 md:flex md:flex-col md:items-center`}>
				{ (eventData.book_1_title && eventData.book_1_title.length) && (
					<Book
						title={eventData.book_1_title}
						link={eventData.book_1_shop_link}
						image={eventData.book_1_image.childImageSharp.gatsbyImageData}
					/>
				)}
				{ (eventData.book_2_title && eventData.book_2_title.length) && (
					<Book
						title={eventData.book_2_title}
						link={eventData.book_2_shop_link}
						image={eventData.book_2_image.childImageSharp.gatsbyImageData}
					/>
				)}
				{ (eventData.book_3_title && eventData.book_3_title.length) && (
					<Book
						title={eventData.book_3_title}
						link={eventData.book_3_shop_link}
						image={eventData.book_3_image.childImageSharp.gatsbyImageData}
					/>
				)}
			</div>
        );
	}

	return (
		<Layout>
			<EventBanner event={eventData} showRegistration={showRegistration()}/>
			{showLiveEvent() ? <LiveEvent event={eventData} eventFormName={eventFormName} livestream={livestream} pageLocation={location} /> : null}
			{/* add the Q&A form to the DOM so it is picked up by netlify */}
			{eventData.event_id === '10-29-1800-main' && (
				<div className={`hidden`}><QandAForm action={`/`} event={eventData} formName={eventFormName} /></div>
			)}
			<section className={`flex flex-row items-start flex-wrap md:pt-12 pb-16 bg-boswell-handwriting`}>
				<div className={`flex items-start flex-wrap w-full`}>
					<EventDetails event={eventData} />
					<BookDetails event={eventData} />
				</div>
			</section>
		</Layout>
	)
}

export const pageQuery = graphql`query ($eventId: String!, $eventLivestreamId: String!) {
  livestream: markdownRemark(
    frontmatter: {event_livestream_id: {eq: $eventLivestreamId}}
  ) {
    html
    frontmatter {
      event_livestream_id
      event_livestream_link_bsl
    }
  }
  event: markdownRemark(frontmatter: {event_id: {eq: $eventId}}) {
    html
    frontmatter {
      author_1_image {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      author_1_image_credit
      author_1_name
      author_2_image {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      author_2_name
      book_1_image {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
      book_1_shop_link
      book_1_title
      book_2_image {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
      book_2_shop_link
      book_2_title
      book_3_image {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
      book_3_shop_link
      book_3_title
      category
      event_compere_name
      event_date
      event_end
      event_ft_id
      event_id
      event_image {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      event_interviewer_name
      event_livestream_link
      event_livestream_code
      event_start
      event_title
      event_type
      event_visible
    }
  }
}
`
